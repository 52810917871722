<template>
    
  <div class="row justify-center">
    <div class="justify-center">
        <h4>Les mangroves</h4>
      </div>
  </div>

  <div class="row justify-center">
    <div class="col-xs-10 col-md-6">

      <div style="display:flex; flex-direction: column; font-size: 18px;">
        <div style="display: flex; justify-content: space-around; align-items: center;">
          <img src="../../../public/img/mangroves/mangraid.png">
          <div style="margin-left: 50px;">
            La mangraid regroupe les joueurs encadrant l'organisation du progress mythique chez Mandragore.<br><br>
            <i style="color: gray;">“Engagez-vous, qu’ils disaient, Engagez vous…”</i>
          </div>
        </div>
        <div style="display: flex; justify-content: space-around; align-items: center;">
          <div style="margin-right: 50px;">
            Le Comité des fêtes s'occupe de l'organisation et du bon déroulement des IRL Mandragore annuelle.<br><br>
            <i style="color: gray;">“C’est l’histoiiiire, de la vie”</i>
          </div>
          <img src="../../../public/img/mangroves/comite_des_fetes.png">
        </div>
        <div style="display: flex; justify-content: space-around; align-items: center;">
          <img src="../../../public/img/mangroves/mandravie.png">
          <span style="margin-left: 50px;">
            Un certain nombre d'activités importantes et récurrentes ont leurs mangroves dédiées, comme raider dans Wow ou se retrouver lors des IRL. Pour les autres activités plus modestes, épisodiques et éphémères, c'est dans la Mandravie qu'on va se retrouver. Par exemple, il pourra s'agir d'autres actitvités sur WoW, d'organiser un BOTC, un tournoi d'Among Us et ou des activités sur FFIV !<br><br>
            <i style="color: gray;">“Pour partager des activités avec les amis faits en chemin”</i>
          </span>
        </div>
        <div style="display: flex; justify-content: space-around; align-items: center;">
          <span style="margin-right: 50px;">
            Ici, on prépare l'accueil et le parcours d'intégration des nouveaux Mandragores&nbsp;!<br><br>
            <i style="color: gray;">“Le meilleur endroit pour rencontrer ses teammates (et les backsteak au mexicain)”</i>
          </span>
          <img src="../../../public/img/mangroves/pepiniere.png">
        </div>
        <div style="display: flex; justify-content: space-around; align-items: center;">
          <img src="../../../public/img/mangroves/mandrasite.png">
          <span style="margin-left: 50px;">
            Cette Mangrove s’occupe de créer le site internet de Mandragore et de le mettre à jour.<br><br>
            <i style="color: gray;">
              “De loin la meilleure des mangroves“ - JCVD<br>
              “J’ai jamais vu un site web aussi beau et bien codé” - Sly Marbo
            </i>
          </span>
        </div>


      </div>


    </div>
  </div>

  <div class="row justify-center" style="margin-top: 40px; margin-bottom: 30px;">
    <div class="col-xs-10 col-md-6" style="display: flex; justify-content: space-evenly;">

    <router-link to="/association/asso">
      <q-btn color="primary" label="◄&nbsp;&nbsp;&nbsp;L'asso" />
    </router-link>

    <router-link to="/association/parrainages">
      <q-btn color="primary" label="Les parrainages&nbsp;&nbsp;&nbsp;►" />
    </router-link>

  </div>
</div>  

</template>  


<style scoped>

.list-element {
  margin-bottom: 10px;
}

.indent {
  text-indent: 50px;
}

</style>